import React from "react"

import SEO from "../components/seo"

export default () => (
  <>
    <SEO />
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "black",
        display: "flex",
      }}
    >
      <iframe
        src="/form.html"
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  </>
)
